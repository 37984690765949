
// This file contains the function that generates embeddings for a page and stores them in the database
import { createClient } from "@supabase/supabase-js";
import { OpenAIEmbeddings } from "langchain/embeddings/openai";
import { RecursiveCharacterTextSplitter } from "langchain/text_splitter";


// Set up OpenAI API key
const openAiKey = process.env.REACT_APP_OPENAI_API_KEY;

// Set up Supabase credentials
const supabaseURL = process.env.REACT_APP_DB_URL;
const supabaseKey = process.env.REACT_APP_ANON_KEY

export const createEmbeddings = async (digital_brain_id: string, page_id: string, page: string) => {
  // Set up Supabase client
  let client;
  if (supabaseURL && supabaseKey) {
  client = createClient(supabaseURL, supabaseKey);
  }

  // create a embeddings model
  const embeddings = new OpenAIEmbeddings({
    openAIApiKey: openAiKey,
  });

  // load text
  // const textLoader = new TextLoader(
  //   "/Users/Kyle/Desktop/dev/projects/SaaS-Project/your-digital-brain/v3/backend/src/utils/test.txt"
  // );
  // const loadedText = await textLoader.load();

  // get text
  const text = page;


  // split and create docs
  const textSplitter = new RecursiveCharacterTextSplitter({
    chunkSize: 2000,
    ...{overlapSize: 100},
  });
  const docs = await textSplitter.createDocuments([text]);

  let documents = [];

  for (let i = 0; i < docs.length; i++) {
    const doc = docs[i].pageContent;
    documents.push(doc);
  }

  // create embeddings
  const embedDocs = await embeddings.embedDocuments(documents);

  // console.log(embedDocs)
  // add vectors and docs to supabase
  const vectors = embedDocs;
  if (client) {

  for (let i = 0; i < vectors.length; i++) {
    const vector = vectors[i];
    const doc = documents[i];
    const { data, error } = await client
    .from("page_section")
    .insert({
      embedding: vector,
      content: doc,
      digital_brain_id: digital_brain_id,
      page_id: page_id,
    });
    if (error) {
      console.log(error);
    }
  }
  }
  return "success"
};



