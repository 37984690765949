// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { query } from "../../utils/query";
import parse from 'html-react-parser';
import "./FullPageAI.css";
import Rive from '@rive-app/react-canvas';
import loadingAnimation from '../../assets/animations/loadingAnimation.riv';
import Send_fill from '../../assets/icons/Send_fill.svg';




export default function FullPageAskAI({
    answers,
    setAnswers,
    setFullScreenOpen,
    fullScreenOpen,
    question,
    setQuestion,
    loading,
    setLoading,
    questionSubmit,
    setQuestionSubmit
}) {

    const [error, setError] = useState([]);
    let digitalBrainId = useParams();
    digitalBrainId = Number(digitalBrainId.digitalBrainId);
    // const questions = document.querySelector('#question')
    // grab the text from the div with id question
    const questions = document.getElementById('question')
    const text = questions?.textContent;
    const [pageIds, setPageIds] = useState([]);
    

   


    useEffect(() => {
        if (question?.length > 0 && fullScreenOpen && questionSubmit) {
            handleAskAIButton();
        }
    }, [question, questionSubmit]);

    const handleAskAIButton = async () => {
        const newQuestion = question;
        setQuestionSubmit(false)


        if (questions) {
            questions.innerHTML = ''
        }

        if (newQuestion === undefined || newQuestion === "") {
            // setError(["Please enter a question."]);
            setAnswers([...answers, "Please enter a question."]);
            setLoading(false);
            return;
        }

        const newAnswers = [...answers, { content: newQuestion, type: 'question' }];
        setAnswers(newAnswers);
        setLoading(true);
        setQuestion("");


        if (newQuestion === undefined || newQuestion === "") {
            // setError(["Please enter a question."]);
            setAnswers([...answers, "Please enter a question."]);
            setLoading(false);
            setQuestionSubmit(false)
            return;
        }

        const queryAnswer = await query(newQuestion, digitalBrainId, pageIds);


        if (queryAnswer) {
            let response = queryAnswer.res.text;
            if (response === undefined) {
                response = queryAnswer.data.answer.res;
            }

            // take the response and take it out of being a string
            response = response.replace(/['"]+/g, '');


            const newAnswers = [...answers, { content: newQuestion, type: 'question' }, { content: response, type: 'answer' }];

            setAnswers(newAnswers)
            setLoading(false);
            setQuestionSubmit(false)

        } else {
            setError([
                "There was an error getting the answer to your question. Please try again.",
            ]);
            console.log(error);
        }
    };


    const handleKeyDown = (e) => {
        if (!loading) {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            setLoading(true);
            setQuestionSubmit(true)

            // handleAskAIButton();
            if (question === undefined || question === "") {
                // setError(["Please enter a question."]);
                setAnswers([...answers, "Please enter a question."]);
                setLoading(false);
                return;
            }
            handleAskAIButton();
        }
    }
    }



    const handleChange = (e) => {
        const questionText = e.target?.textContent
        setQuestion(questionText)
    }

    const handleSubmitButton = (e) => {
        e.preventDefault();
        setLoading(true);
        setQuestionSubmit(true)

        if (question === undefined || question === "") {
            // setError(["Please enter a question."]);
            setAnswers([...answers, "Please enter a question."]);
            setLoading(false);
            return;
        }
        handleAskAIButton();

    }


    return (
        <>
            <div className="text-text-color flex justify-center items-center absolute z-1 w-full full-screen-chat">
                {answers?.length > 0 && (
                    <div className="flex items-center w-full">
                        <div className="text-text-color w-full full-screen-chat overflow-y-scroll h-screen pb-[10rem] ">
                            {answers?.map((element, i) => (
                                <div key={i} className="flex flex-col ">
                                    {element.type === "question" ? (
                                        <div className="flex flex-col w-full ">
                                            <div className="p-5 pb-5 bg-chat-bubble-question text-text-color w-full pl-[5rem] pr-[5rem]">{parse(element.content)}</div>
                                        </div>
                                    ) : element.type === "answer" ? (
                                        <div className="flex flex-col ">
                                            <div className="p-5 pb-5 bg-chat-bubble-answer w-full pl-[5rem] pr-[5rem]">{parse(element.content)}</div>
                                        </div>
                                    ) : (
                                        <div className="flex flex-col">
                                            <div className="p-5 pb-5 bg-chat-bubble-answer text-error w-full">{parse(element)}</div>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div id="question" className="w-[34rem] h-auto max-h-[10rem] break-normal rounded-md border-[.1px] text-text-color p-2 focus:outline-none resize-none break-words overflow-y-hidden bg-primary bottom-2 absolute"
                    contentEditable="true"
                    onKeyDown={(e) => handleKeyDown(e)}
                    onInput={(e) => handleChange(e)}
                ></div>
                <button disabled={loading} className="bottom-[1.1rem] absolute ml-[32rem]" onClick={(e) => handleSubmitButton(e)}>{loading ? <Rive src={loadingAnimation} style={
                {
                  width: '1.5rem',
                  height: '1.5rem',
                }
              } /> : <img src={Send_fill} alt="send" /> }</button>
            </div>

        </>

    );
}