import { useState, useEffect } from "react";
const useContextMenuFolders = () => {
    const [foldersClicked, setFoldersClicked] = useState(false);
    const [folderPoints, setFolderPoints] = useState({
        x: 0,
        y: 0,
    });
    useEffect(() => {
        const handleClick = () => setFoldersClicked(false);
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, []);
    return {
        foldersClicked,
        setFoldersClicked,
        folderPoints,
        setFolderPoints,
    };
};
export default useContextMenuFolders;