// @ts-nocheck
import React, { useEffect } from "react";
import LoginButton from "../../components/Login/Login.tsx";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "../../components/Logout/Logout.tsx";
import { useNavigate } from "react-router-dom";
import Waitlist from "../../components/Waitlist/index.tsx";
import UseCase from "../../components/Waitlist/UseCase.tsx";

export default function WaitlistPage() {

    return (

        <>
            <div className="w-full">
                <Waitlist />
            </div>
            <div>
                <UseCase />
            </div>
        </>

    );

}