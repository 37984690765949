import React from "react";
import { useNavigate } from "react-router-dom";
import "../Navigation/index.css";

const LoginButton = () => {
  const navigate = useNavigate();

  const handleLoginButton = async () => {
    navigate("/login");
  };

  return (
    <>
      <button className="sign-in-button" onClick={handleLoginButton}>
        Sign in
      </button>
    </>
  );
};

export default LoginButton;
