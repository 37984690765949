import React from "react";
import { client } from "../../utils/supabaseClient.ts";
import { useNavigate } from "react-router-dom";
import "../Navigation/index.css";


const LogoutButton = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    const { error } = await client.auth.signOut()
    if (error) {
      console.log(error);
    }
    else {
      navigate("/");
    }
  };
  return (
    <div className="sign-in-button">
    <button onClick={handleLogout}>
      Log Out
    </button>
    </div>
  );
};

export default LogoutButton;
