// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { client } from "../../utils/supabaseClient.ts";
import googleIcon from "../../assets/GoogleLogoG.svg";
import discordIcon from "../../assets/DiscordLogoDL.svg";
import { useNavigate } from 'react-router-dom';

const supabase = client


const SignUpPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [varificationMSG, setVarificationMSG] = useState('');

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const handleSignUp = async (e) => { 
        e.preventDefault()
        const { data: user, error } = await supabase.auth.signUp({
            email: email,
            password: password,
          })
         
          const defaultUserName = email.split('@')[0]
          if (!password || !email){
            setError("Please fill in all fields")
            return
            } else if (error) {
            setError("Invalid email or password")
            return
          } if (user) {
            setVarificationMSG("Please check your email for a verification link")
            return
          }
    };
    async function signUpWithGoogle() {
        const { data, error } = await supabase.auth.signInWithOAuth({
            provider: 'google',
        })
    }

    async function signUpWithDiscord() {
        const { data, error } = await supabase.auth.signInWithOAuth({
            provider: 'discord',
        })
    }

    useEffect(() => {
            
            
        }, [error, varificationMSG])


    return (
        <div className="flex flex-col items-center justify-center min-h-screen">
            <div className="p-8 rounded-lg w-full sm:w-3/4 md:w-1/2 lg:w-2/5">
                <h2 className="text-[1.7rem] mb-6 text-text-color text-center pb-[1rem] innder-shadow" >Sign up for your Digital Brain</h2>
                {error && <p className="text-red-500 text-center mb-4">{error}</p>}
                {varificationMSG && <p className="text-purple text-center mb-4">{varificationMSG}</p>}
                <form onSubmit={(e) => handleSignUp(e)} className="">
                <div className="mb-4">
                    <label className="block text-text-color mb-2 text-[.8rem]" htmlFor="email">
                        Email:
                    </label>
                    <input
                        className="appearance-none bg-grey focus:border-[.5px]  rounded w-full mb-2 py-1 px-3 text-text-color leading-tight focus:outline-none focus:shadow-outline"
                        id="email"
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                    />
                </div>
                <div className="mb-6">
                    <label className="block text-text-color mb-2 text-[.8rem]" htmlFor="password">
                        Password:
                    </label>
                    <input
                        className="appearance-none bg-grey mb-2 rounded w-full py-1 px-3 text-text-color leading-tight focus:outline-none focus:shadow-outline focus:border-[.5px]"
                        id="password"
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                    />
                </div>
                
                <div className="flex flex-col items-center ">
                    <button
                        className="bg-purple hover:bg-dark-purple mb-4 text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                        onClick={handleSignUp}
                        type="submit"
                    >
                        Sign Up
                    </button>
                    
                    <div className="my-4 flex items-center w-full">
                        <hr className="border-gray-300 border-1 w-full rounded-md" />
                        <div className="mx-4 text-text-color font-bold">or</div>
                        <hr className="border-gray-300 border-1 w-full rounded-md" />
                    </div>
                    
                    <div className="flex flex-col sm:flex-row items-center justify-center w-full">
                        <button
                            className="bg-primary hover:bg-dark-purple text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline w-full mb-2 sm:mb-0 mx-6 sm:mx-2 border-[.1px] flex items-center justify-center"
                            onClick={signUpWithGoogle}
                        >
                            <img className="w-6 h-6" src={googleIcon} alt="Google Icon" />
                        </button>
                        <button
                            className="bg-primary hover:bg-dark-purple text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline w-full mx-6 sm:mx-2 border-[.1px] flex items-center justify-center"
                            onClick={signUpWithDiscord}
                        >
                            <img className="w-6 h-6" src={discordIcon} alt="Discord Icon" />
                        </button>

                    </div>
                    
                </div>
                </form>
                <div className="flex flex-col w-full">
            <p className="text-text-color mt-[6rem] text-[.7rem] ">
                        Already have an account?{" "}
                        <a href="/login" className="text-purple underline">
                            Log in.
                        </a>
                    </p>
                </div>
               
            </div>
            
        </div>
    );
};

export default SignUpPage;

