import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Editor from "./components/Editor/index.tsx";
import DigitalBrain from "./pages/DigitalBrain/index.tsx";
import Page from "./pages/DigitalBrain/Page.tsx";
import UserAccount from "./pages/UserAccount/index.tsx";
import HomePage from "./pages/HomePage/index.tsx";
import AskAI from "./pages/DigitalBrain/AskAI.tsx";
import WaitlistPage from "./pages/Waitlist/index.tsx";
import WaitListConfrimationPage from "./pages/Waitlist/WaitListConfrimationPage.tsx";
import LoginPage from "./pages/LoginPage/index.tsx";
import SignUpPage from "./pages/SignUpPage/index.tsx";
import MobileComingSoon from "./pages/Mobile/Mobile.tsx";
import "./index.css";

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route exact path="/waitlist" element={<WaitlistPage />} />
        <Route exact path="/login" element={<LoginPage />} />
        <Route exact path="/signup" element={<SignUpPage />} />
        <Route
          exact
          path="/waitlist/confirm"
          element={<WaitListConfrimationPage />}
        />
        <Route
          exact
          path="/:user/digital-brain/:digitalBrainId"
          element={<DigitalBrain />}
        />
        <Route
          exact
          path="/:user/digital-brain/:digitalBrainId/page/:id"
          element={<DigitalBrain />}
        />
        <Route exact path="/:user" element={<UserAccount />} />
        <Route exact path="/mobile" element={<MobileComingSoon />} />
        <Route
          exact
          path="/:user/digital-brain/:digitalBrainId/ask-ai"
          element={<AskAI />}
        />
      </Routes>
    </>
  );
}

export default App;
