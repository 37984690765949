import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { query } from "../../utils/query";
import parse from 'html-react-parser';
import Modal from 'react-modal';
import { useNavigate } from "react-router-dom";
import Rive from '@rive-app/react-canvas';
import loadingAnimation from '../../assets/animations/loadingAnimation.riv';
import Send_fill from '../../assets/icons/Send_fill.svg';



export default function AskAISidePanel({
  isOpen,
  setIsOpen,
  question,
  setQuestion,
  loading,
  setLoading,
  questionSubmit,
  setQuestionSubmit,
  pages,
  setPages,
  user,
}) {
  const [answers, setAnswers] = useState([]);
  const [error, setError] = useState([]);
  let digitalBrainId = useParams();
  digitalBrainId = Number(digitalBrainId.digitalBrainId);
  // const questions = document.querySelector('#question')
  const questions = document.getElementById('question')
  const text = questions?.textContent;
  const [pageIds, setPageIds] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPageIds, setSelectedPageIds] = useState([]);
  const [filteredPages, setFilteredPages] = useState([]);
  const navigate = useNavigate();
  const defaultUserName = user?.email.split("@")[0];

  useEffect(() => {
    if (question?.length > 0 && questionSubmit) {
      handleAskAIButton();
    }
  }, [question, questionSubmit])


  const handleAskAIButton = async () => {
    // console.log('Ask AI button clicked')
    const newQuestion = question
    if (questions) {
      questions.innerHTML = ''
    }

    const newAnswers = [...answers, { content: newQuestion, type: 'question' }];
    setAnswers(newAnswers);
    setLoading(true);
    setQuestion('');

    const queryAnswer = await query(newQuestion, digitalBrainId, pageIds)

    if (queryAnswer) {

      let response = queryAnswer.res.text

      const newAnswers = [...answers, { content: newQuestion, type: 'question' }, { content: response, type: 'answer' }];

      setAnswers(newAnswers)
      setLoading(false)
      setQuestionSubmit(false)


    } else {
      setError(['There was an error getting the answer to your question. Please try again.'])
      console.log(error)
    }

  }

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAskAIButton();
  }

  const handleChange = (e) => {
    const questionText = e.target.textContent
    setQuestion(questionText)
  }

  const handleKeyDown = (e) => {
    if (!loading) {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        setLoading(true)
        handleAskAIButton();
      }
    }
  }


  const handleSubmitButton = (e) => {
    e.preventDefault();
    setLoading(true)
    handleAskAIButton();
  }

  const handlePageSelection = (pageId, checked) => {
    if (checked && !selectedPageIds.includes(pageId)) {
      setSelectedPageIds([...selectedPageIds, pageId]);
    } else {
      setSelectedPageIds(selectedPageIds.filter((id) => id !== pageId));
    }
  };

  const onModalClose = () => {
    setIsModalOpen(false)
    setPageIds(selectedPageIds)

  }

  // todo: This will need some work

  // const handleFilteredPageClick = (pageId) => {
  //   navigate(
  //     `/${defaultUserName}/digital-brain/${digitalBrainId}/page/${pageId}`
  //   );

  // }

  useEffect(() => {
    if (pages?.length > 0) {
      setFilteredPages(pages.filter((page) => pageIds.includes(page.id)));
    }
  }, [pageIds]);





  return (
    <>
      <Modal isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)} className="fixed inset-0 bg-gray-900 bg-opacity-75 flex justify-center items-center ">
        <div className="bg-sidebar-grey w-full max-w-md p-6 text-text-color border-[.2px] flex flex-col">
          <h2 className="text-lg font-medium mb-4">Select pages to chat with: </h2>
          <ul className="">
            {pages.map((page) => (
              <li key={page.id}>
                <label className="flex items-center">
                  <input type="checkbox" value={page.id} checked={selectedPageIds.includes(page.id)} onChange={(e) => handlePageSelection(page.id, e.target.checked)} className="mr-2" />
                  <span className="text-s">{page.title}</span>
                </label>
              </li>
            ))}
          </ul>
          <button onClick={onModalClose} className="bg-purple hover:bg-dark-purple mb-4 text-white font-bold py-1 px-4 rounded focus:outline-none focus:shadow-outline w-full mt-[2rem]">Close</button>
        </div>
      </Modal>
      <div className="w-full p-[1rem] pb-[1rem]">
        <div className="text-lg text-text-color">
          Search in:
        </div>
        <div className="text-xs">
          <div className="flex flex-wrap mt-2 mb-2">
            {pageIds?.length === 0 && (
              <div className="bg-sidebar-grey rounded-md px-4 py-[.2rem] mr-2 mt-2 flex">
                Digital Brain
              </div>
            )}
            {pageIds?.length > 0 && (
              <div className="flex flex-wrap mt-2">
                {filteredPages.map((page) => (
                  <div key={page.id} /* onClick={() => handleFilteredPageClick(page.id)} todo: needs some work */ className="bg-sidebar-grey rounded-md px-4 py-[.2rem] mr-2 mt-2 flex hover:bg-purple">
                    {page.title}
                  </div>
                ))}
              </div>
            )}
            <button onClick={() => setIsModalOpen(true)} className="bg-primary text-text-color rounded-md px-4 py-[.2rem] mt-3">+ add more</button>
          </div>
          <span className="ml-2">{`${pageIds?.length === undefined ? 0 : pageIds?.length} selected`}</span>
        </div>
      </div>
      <div className="text-text-color w-full full-screen-chat">
        {answers?.length > 0 && (
          <div className="text-text-color w-full">
            {answers?.map((element, i) => (
              <div key={i} className="flex flex-col items-start">
                {element.type === "question" ? (
                  <div className="flex flex-col items-start w-full">
                    <div className="p-5 pb-5 bg-chat-bubble-question text-text-color w-full">{parse(element?.content)}</div>
                  </div>
                ) : (
                  <div className="flex flex-col items-start">
                    <div className="p-5 pb-5 bg-chat-bubble-answer w-full">{parse(element?.content)}</div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        <div className="flex justify-center items-center w-full reletive">
          <div className="absolute bottom-2 w-[95%]">
            <div
              id="question"
              className="h-auto max-h-[10rem] break-normal rounded-md border-[.1px] text-text-color pr-10 p-2 focus:outline-none resize-none break-words overflow-y-hidden bg-primary bottom-2 w-full"
              contentEditable="true"
              onKeyDown={(e) => { handleKeyDown(e) }}
              onInput={(e) => { handleChange(e) }}
            ></div>
            <button
              disabled={loading}
              className="bottom-2 absolute right-2"
              onClick={(e) => { handleSubmitButton(e) }}
            >
              {loading ? <Rive src={loadingAnimation} style={
                {
                  width: '1.5rem',
                  height: '1.5rem',
                }
              } /> : <img src={Send_fill} alt="send" /> }
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
