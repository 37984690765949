// @ts-nocheck
import React, { useState } from "react";
import MainEditor from "../../components/Editor/index.tsx";
import AskAI from "../../components/AskAI/AskAI.tsx";
import Chat from "./Chat.tsx";
import "./Chat.css";

export default function Page({
  pages,
  currentPageId,
  setCurrentPageId,
  pageSections,
  digitalBrainId,
  isOpen,
  setIsOpen,
  question,
  setQuestion,
  loading,
  setLoading,
  questionSubmit,
  setQuestionSubmit,
  setPages,
  user,
  dataLoading,
  setDataLoading
}) {
  

  return (
    <div className={isOpen ? "main-content shrink" : "main-content"}>
      <MainEditor
        pageSections={pageSections}
        digitalBrainId={digitalBrainId}
        currentPageId={currentPageId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        dataLoading={dataLoading}
        setDataLoading={setDataLoading}
        
      />
        {/* {!isOpen && (
          <AskAI
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          question={question}
          setQuestion={setQuestion}
          loading={loading}
          setLoading={setLoading}
          questionSubmit={questionSubmit}
          setQuestionSubmit={setQuestionSubmit}
          />
        )} */}
      <Chat 
      isOpen={isOpen} 
      setIsOpen={setIsOpen}
      setQuestion={setQuestion}
      question={question}
      loading={loading}
      setLoading={setLoading}
      questionSubmit={questionSubmit}
      setQuestionSubmit={setQuestionSubmit}
      pages={pages}
      setPages={setPages}
      user={user}
      
      />
    </div>
  );
}
