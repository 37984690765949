import React, { useEffect, useState } from "react";

const MobileComingSoon = () => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  useEffect(() => {
    // Check if the user is using a mobile device
    setIsMobileDevice(
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    );
  }, []);

  return (
    isMobileDevice && (
      <div>
        <h1>Mobile Functionality Coming Soon</h1>
        <p>Please rejoin via desktop.</p>
      </div>
    )
  );
};

export default MobileComingSoon;
