// @ts-nocheck
import React from "react";
import confetti from "../../assets/confetti.gif"
import discord from "../../assets/discord.png"

export default function WaitlistConfirmation() {

    const handleDiscordClick = () => {
        window.open("https://discord.gg/ChaUXkMceq", "_blank")
    }

    return (
        <>
            <div className="relative">
                <div className="flex flex-col items-center justify-center text-text-color">
                    <div className="flex flex-col items-center justify-center mt-[10rem] pl-[5rem] pr-[5rem] pb-[3rem]">
                        <h1 className="text-4xl font-bold text-center pb-[2rem]">Thank you for joining the waitlist!</h1>
                        <p className="text-xl text-center">We will notify you when you are able to join.</p>
                        <p className="text-xl text-center" >To ensure that you don't miss any updates and have the opportunity to be among the first to experience our Beta, we invite you to join our vibrant Discord Community.</p>
                    </div>
                    <img src={confetti} alt="conffetti" className="w-[15rem] h-[13rem]" />
                </div>

            </div>
            <div className="w-[3rem] h-[3rem] cursor-pointer absolute bottom-5 right-5" >
            <img src={discord} alt="discord" className="" onClick={handleDiscordClick} />
            </div>
        </>
    )
}
