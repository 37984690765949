import { useState, useEffect } from "react";
const useContextMenuPages = () => {
    const [pagesClicked, setPagesClicked] = useState(false);
    const [pagesPoints, setPagesPoints] = useState({
        x: 0,
        y: 0,
    });
    useEffect(() => {
        const handleClick = () => setPagesClicked(false);
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, []);
    return {
        pagesClicked,
        setPagesClicked,
        pagesPoints,
        setPagesPoints,
    };
};
export default useContextMenuPages;