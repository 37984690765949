// @ts-nocheck
import React, { useEffect, useState } from "react";
import LoginButton from "../../components/Login/Login.tsx";
import LogoutButton from "../../components/Logout/Logout.tsx";
import { useNavigate } from "react-router-dom";
import Navigation from "../../components/Navigation/index.tsx";
import "./index.css";
import Rive from '@rive-app/react-canvas';
import pageLoading from '../../assets/animations/pageLoading.riv';

import { client } from "../../utils/supabaseClient.ts";
import { switchTabs } from "../../utils/switchTabs.ts";

export default function HomePage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({});

  const getUser = async () => {
    const {
      data: { user },
    } = await client.auth.getUser();

    setIsLoading(false);


    if (user) {
      setUser(user);
      const defaultUserName = user?.email?.split("@")[0];
      setIsAuthenticated(true);
    }
    
  };

  const handleLoginButton = async () => {
    navigate("/login");
  };

  const handleDashboard = async () => {
    navigate(`/${user?.email?.split("@")[0]}`);
  };

  useEffect(() => {
    getUser();
  }, []);

  const handleComunityButton = async () => {
    window.open("https://discord.gg/ChaUXkMceq", "_blank");
  };
  


  return (
    <>
      <div className="text-text-color">
        {!isLoading && (
        <div>
          <div className="splash-wrapper">
            <Navigation />
            <div className="splash-container">
              <h1 className="slogan">
                Your personal AI assistant, powered by{" "}
                <a className="slogan-eyecatcher">Your Notes</a>
              </h1>
              <div className="slogan-description">
                Give a voice to your ever-growing
                knowledgebase
              </div>
              {!isAuthenticated && (
              <button onClick={handleLoginButton} className="call-to-action cta-v2">
               Get started
              </button>
              )}
              {isAuthenticated && (
              <button onClick={handleDashboard} className="call-to-action cta-v2">
               Dashboard
              </button>
              )}
            </div>
          </div>
          <section id="tab01" className="demo-section flex">
            <div className="demo-flex-div">
              <h1>Unlocking the Potential of Your Notes with AI Guidance</h1>
              <div className="demo-picture"><img src="appDemoSS.jpg" alt="" /></div>
            </div>
            <div className="demo-flex-div demo-tabs">
              <button onClick={switchTabs} className="demo-tab">Add a note</button>
              <button className="demo-tab active">Search in chat</button>
              <h1>AI Guidance</h1>
              <p>With your very own Ai chatbot trained on your notes it will make finding what you need in your notes a breaze.</p>
            </div>
          </section>
          <section id="tab02" className="demo-section flex">
            <div className="demo-flex-div">
              <h1>Dump everything into your notes</h1>
              <div className="demo-picture"><img src="appDemoSS.jpg" alt="" /></div>
            </div>
            <div className="demo-flex-div demo-tabs">
              <button className="demo-tab active">Add a note</button>
              <button onClick={switchTabs} className="demo-tab">Search in chat</button>
              <h1>Never forget again</h1>
              <p>Dont worry about cleaning up your notes. Just dump what you find into your notes and let the ai do the hard work of looking through it for what and helping recall what you need. </p>
            </div>
          </section>
          <section className="what-section">
            <h1>What can you do with Digital Brain</h1>
            <p>Empowering Knowledge Mastery</p>
            <div className="flex">
              <div className="what-card">
                <h2>Understand and organize notes</h2>
                <p>The AI assistant helps you make sense of extensive information by identifying key concepts, providing examples, and explaining complex topics. It assists in organizing your notes effectively.</p>
              </div>
              <div className="what-card">
                <h2>Bridging knowledge gaps</h2>
                <p>The AI assistant identifies areas where you need further clarification or assistance, filling in the gaps in your understanding and helping you connect different pieces of information.</p>
              </div>
              <div className="what-card">
                <h2>Efficient studying and preparation</h2>
                <p>The AI assistant generates customized study questions based on your notes, allowing you to test your knowledge and prepare effectively for exams or assessments. It supports your learning journey by providing valuable insights and aiding in focused studying.</p>
              </div>
            </div>
          </section>
          <section className="community-section">
                <div>
                  <h1>Join the community</h1>
                  <p>Join our community of students and educators who are using Digital Brain to enhance their learning experience.</p>
                  <button onClick={handleComunityButton} className="community-button">Discord</button>
                </div>
          </section>
          <section className="started-section">
            <button onClick={handleLoginButton} className="call-to-action cta-v2">
               Get started
              </button>
              <h1>Unlocking Insights, Bridging Gaps, and Mastering your Notes</h1>
          </section>
          <footer>
            <div className="footer-container">
              <div className="footer-flex logo"></div>
              <div className="footer-flex">
                <div>
                  <a href="/">About</a>
                  <a href="/">Blog</a>
                  <a href="/">Contact</a>
                </div>
                <div>
                  <a href="/">Terms & Privacy</a>
                  <a href="https://discord.gg/ChaUXkMceq">Discord</a>
                </div>
              </div>
            </div>
            <div className="footer-container trademark">
                <div>
                  @2023 Digital Brain
                </div>
                <div>
                  All rights reserved
                </div>
            </div>
          </footer>
        </div>
        )}
        {isLoading && (
          <div className="flex h-screen">
            <h1 className="m-auto"><Rive src={pageLoading} style={
                {
                  width: '20.5rem',
                  height: '20.5rem',
                }
              } /></h1>
          </div>
        )}
      </div>
    </>
  );
}
