export const switchTabs = () => {
    const [tab01, tab02] = [document.getElementById("tab01"), document.getElementById("tab02")]; if(!tab01 || !tab02) return;

    if(tab01?.style.display === "none"){
        tab01.style.display = "flex"
        tab02.style.display = "none"
    } else {
        tab01.style.display = "none"
        tab02.style.display = "flex"
    }
}