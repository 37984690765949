// @ts-nocheck
import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import getformattedPage from "../../utils/getPages.ts";
import { useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import { client } from "../../utils/supabaseClient.ts";
import { createEmbeddings } from "../../utils/generate_embeddings.ts";
import Save_light from "../../assets/icons/Save_light.svg"
import Save_light_purple from "../../assets/icons/Save_light_purple.svg"
import Question_light from "../../assets/icons/Question_light.svg"
import Question_light_purple from "../../assets/icons/Question_light_purple.svg"
import Rive from '@rive-app/react-canvas';
import pageLoading from '../../assets/animations/pageLoading.riv';
import check_ring_round_light from '../../assets/icons/check_ring_round_light.svg';
import loading from '../../assets/animations/loading.riv';



export default function MainEditor({
  pageSections,
  digitalBrainId,
  currentPageId,
  isOpen,
  setIsOpen,
  setDataLoading,
  dataLoading,
}) {
  const editorRef: any = useRef(null);
  const [sections, setSections] = useState(pageSections);
  const sizeLimit = 4000;
  const [count, setCount] = useState(0);
  const [data, setData] = useState("");
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const pageId = useParams();
  const [saveHover, setSaveHover] = useState(false);
  const [questionHover, setQuestionHover] = useState(false);

  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);

  const charCount = (editor) => editor.getContent({ format: "text" }).length;

  useEffect(() => {
    onLoad();
  }, [currentPageId]);

  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };

  const handleUpdate = (value, editor) => {
    let content = editor.getContent();
    const cCount = charCount(editor);
    setCount(cCount);
    setSections(content);
    setSaving(false);
    setSaved(false);
  };

  const handleBeforeAddUndo = (evt, editor) => {
    if (charCount(editor) > sizeLimit) {
      evt.preventDefault();
    }
  };

  const onSave = async () => {
    setSaving(true);

    try {
      const formatedContentState = editorRef.current.getContent();
      const unformatedContentState = editorRef.current.getContent({
        format: "text",
      });


      const { data: deleteRes, error: errorRes } = await client
        .from("page_section")
        .delete()
        .match({ page_id: pageId.id });


      if (errorRes) {
        console.error(errorRes);
      }


        const { data: formatedPage, error: formatedError } = await client
          .from("formatted_page")
          .select("*")
          .eq("page_id", pageId.id);

        if (formatedError) {
          console.log(formatedError);
          throw formatedError;
        }


        if (formatedPage.length > 0) {
          const { data: updatedData, error: updateError } = await client
            .from("formatted_page")
            .update({ formatted_page: formatedContentState })
            .eq("page_id", pageId.id);

          if (updateError) {
            throw updateError;
          }



      } else {
        const { data, error } = await client
          .from("formatted_page")
          .insert([
            { formatted_page: formatedContentState, page_id: currentPageId },
          ]);

        if (error) {
          console.log(error);
          throw error;
        }
      
    

      }

      await createEmbeddings(digitalBrainId, currentPageId, unformatedContentState)


      setSaving(false);
      setSaved(true);

    } catch (err) {
      console.error(err);
    }
  };

  const onLoad = async () => {
    setLoaded(false);
    let page = await getformattedPage(currentPageId);
    if (page?.data.length === 0) {
      await setSections("");
      await setLoaded(true);
      setDataLoading(false);
      return;
    }
    const content = page?.data[0].formatted_page;
    await setSections(content || "");
    await setLoaded(true);
    setDataLoading(false);
    if (editorRef.current) {
      setLoaded(false);
      editorRef.current.setContent(content);
      setLoaded(true);
    }
  };

  const openAiSidebar = () => {
    setIsOpen(!isOpen);
  };



  return (
    <>
      {loaded && (
        <>
          <div id="editor" className="absolute top-0 pt-[3rem] right-0 h-[100vh] w-full bg-sidebar-grey">
            <Editor
              apiKey="7vt3kpct0014sdw2chns3mqo7oqj588dlmi3ak5vs284pwsj"
              onInit={(evt, editor) => (editorRef.current = editor)}
              initialValue={sections}
              onBlur={handleUpdate}
              onBeforeAddUndo={handleBeforeAddUndo}
              init={{
                selector: 'input',
                content_style: "body {color:#F5F5F5; overflow-x: hidden; overflow-y: scroll; padding-top:2rem; z-index: -1;}",
                skin_url: "/skins/ui/CUSTOM",
                placeholder: "Start typing here...",
                resize: false,
                statusbar: false,
                content_css: "/skins/content/CUSTOM",
                skin: "CUSTOM",
                menubar: false,
                // toolbar: false,
                plugins: 
                  "advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table code help wordcount",
                toolbar:
                  "undo redo | formatselect | " +
                  "bold italic backcolor | alignleft aligncenter " +
                  "alignright alignjustify | bullist numlist outdent indent | " +
                  "removeformat | help",
                  contextmenu: "undo redo | formatselect | " +
                  "bold italic backcolor",
              }}
              toolbar_mode="wrap"
            />
            <div className="absolute top-[.1rem] right-0">
              {saving && <div className=""><Rive src={loading} style={
                {
                  width: '1.5rem',
                  height: '1.5rem',
                  marginRight: '1rem',
                  marginTop: '.7rem'

                }
              } /></div>}
              {saved && <div className="relative top-[.7rem] right-[1rem]"><img src={check_ring_round_light}/></div>}
              {!saving && !saved && (
                <>
                {!saveHover && (
              <button
                onClick={onSave}
                disabled={saving || saved}
                className="bg-sidbar-grey text-white font-bold rounded focus:outline-none focus:shadow-outline sm:py-[.7rem] sm:px-4  items-center justify-center flex"
                id="save-button"
                onMouseEnter={() => setSaveHover(true)}
                onMouseLeave={() => setSaveHover(false)}
              >
                <img src={Save_light}  />
              </button>
              )}
              {saveHover && (
              <button
                onClick={onSave}
                disabled={saving || saved}
                className="bg-sidbar-grey text-white font-bold rounded focus:outline-none focus:shadow-outline sm:py-[.7rem] sm:px-4  items-center justify-center flex"
                id="save-button"
                onMouseEnter={() => setSaveHover(true)}
                onMouseLeave={() => setSaveHover(false)}
              >
                <img src={Save_light_purple}  />
              </button>
              )}
            </>
              )}
            </div>
            <div className="absolute top-[.1rem] right-[2rem]">
              {!questionHover && (
              <button
                onClick={openAiSidebar}
                disabled={saving || saved}
                className="bg-sidbar-grey text-white font-bold rounded focus:outline-none focus:shadow-outline sm:py-[.7rem] sm:px-4  items-center justify-center flex"
                id="save-button"
                onMouseEnter={() => setQuestionHover(true)}
                onMouseLeave={() => setQuestionHover(false)}
              >
                <img src={Question_light}  />
              </button>
              )}
              {questionHover && (
              <button
                onClick={openAiSidebar}
                disabled={saving || saved}
                className="bg-sidbar-grey text-white font-bold rounded focus:outline-none focus:shadow-outline sm:py-[.7rem] sm:px-4  items-center justify-center flex"
                id="save-button"
                onMouseEnter={() => setQuestionHover(true)}
                onMouseLeave={() => setQuestionHover(false)}
              >
                <img src={Question_light_purple}  />
              </button>
              )}
              </div>  
          </div>
        </>
      )}
      {!loaded && (
        <div className="flex h-screen bg-sidebar-grey">
          <h1 className="m-auto"><Rive src={pageLoading} style={
                {
                  width: '20.5rem',
                  height: '20.5rem',
                }
              } /></h1>
        </div>
      )}
    </>
  );
}
