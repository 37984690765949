// @ts-nocheck
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Rive from '@rive-app/react-canvas';
import loadingAnimation from '../../assets/animations/loadingAnimation.riv';
import Send_fill from '../../assets/icons/Send_fill.svg';

export default function AskAI({
  selectedSuggestion,
  question,
  setQuestion,
  isOpen,
  setIsOpen,
  setAnswers,
  answers,
  setFullScreenOpen,
  fullScreenOpen,
  loading,
  setLoading,
  questionSubmit,
  setQuestionSubmit,
}) {
  const [error, setError] = useState(null);
  let digitalBrainId = useParams();
  digitalBrainId = Number(digitalBrainId.digitalBrainId);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setFullScreenOpen(true);
    setQuestionSubmit(true);
    if (question === undefined || question === "") {
      setAnswers([...answers, "Please enter a question."]);
      setLoading(false);

      return;
    }
  };

  

  useEffect(() => {
    if (selectedSuggestion) {
      setQuestion(selectedSuggestion);
    }
  }, [selectedSuggestion]);

  return (
    <>
      <div className="text-text-color">
        {!fullScreenOpen && (
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Ask a question"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              className="bg-primary w-[25rem] h-[2rem] rounded-md border-[.1px] text-text-color p-2 focus:outline-none"
              disabled={loading}
            />
            <button type="submit" className="ml-2 mt-1 absolute" disabled={loading}>
            {loading ? <Rive src={loadingAnimation} style={
                {
                  width: '1.5rem',
                  height: '1.5rem',
                }
              } /> : <img src={Send_fill} alt="send" /> }
            </button>
          </form>
        )}
      </div>
    </>
  );
}
