// @ts-nocheck
import React, { useState } from "react";
import "./Chat.css";
import AskAISidePanel from "../../components/AskAI/AskAISidePanel.jsx";
import tryangle from "../../assets/Polygon.png";

const Chat = ({
  isOpen,
  setIsOpen,
  setQuestion,
  question,
  loading,
  setLoading,
  questionSubmit,
  setQuestionSubmit,
  pages,
  setPages,
  user

}) => {
  const toggleSidebar = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className="chat-wrapper">
        <div className={`${["sidebar-container"]} ${isOpen ? "open" : ""}`}>
          <div className="sidebar">
            <div className="menu-bar border-l-[.3px]">
              <div className="menu">
                <ul className="menu-links">
                  {isOpen && (
                    <AskAISidePanel
                      setQuestion={setQuestion}
                      question={question}
                      loading={loading}
                      setLoading={setLoading}
                      questionSubmit={questionSubmit} 
                      setQuestionSubmit={setQuestionSubmit}
                      pages={pages}
                      setPages={setPages}
                      user={user}
                    />
                  )}
                </ul>
              </div>
            </div>
            {/* <button className="toggle-button" onClick={toggleSidebar}>
              <img src={tryangle} alt="tryangle" />
            </button> */}
          </div>

        </div>

      </div>



    </>
  );
};

export default Chat;
