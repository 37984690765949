// @ts-nocheck

import React, { useState } from "react";
import chatbot from "../../assets/chatbot.gif";
import bridge from "../../assets/bridge.gif";
import logo from "../../assets/logo.png";
import note from "../../assets/note.gif"



const UseCase = () => {
  const [selectedSection, setSelectedSection] = useState("");
  const [title, setTitle] = useState("Being your one stop knowledge base");

  const handleMouseEnter = (section: string) => {
    setSelectedSection(section);
    setTitle(`${section}`);
  };


  return (
    <div className="flex flex-col lg:flex-row pb-[10rem] lg:pl-10 md:pl-5 sm:pl-3 lg:pr-[5rem] md:pr-5 sm:pr-2 mb-[5rem] text-text-color">
      <div className="lg:w-1/2 p-4 border-2 pb-auto border-black-500 waitlist-left-content min-w-[20rem] min-h-[20rem]">
        <h2 className="text-3xl font-Roboto font-bold mb-4">{title}</h2>
        {selectedSection === "" && <p className="text-xs lg:text-sm">This note taking app is an all-in-one solution for storing and organizing your ever-growing knowledgebase. Whether you're a student, a professional working on a project, or just a curious individual, you can effortlessly compile all the information you gather into this app. With the help of its smart AI assistant, you can easily make sense of your notes and find the information you need in seconds. As you continue to use this app, your AI assistant learns and grows alongside your knowledge base, becoming an even more valuable resource that you can rely on. <img src={logo} alt="" className="w-[15rem] h-[13rem] ml-[6rem]" /> </p>  }
        {selectedSection === "Being your one stop knowledge base" && <p className="text-xs lg:text-sm">This note taking app is an all-in-one solution for storing and organizing your ever-growing knowledgebase. Whether you're a student, a professional working on a project, or just a curious individual, you can effortlessly compile all the information you gather into this app. With the help of its smart AI assistant, you can easily make sense of your notes and find the information you need in seconds. As you continue to use this app, your AI assistant learns and grows alongside your knowledge base, becoming an even more valuable resource that you can rely on. <img src={logo} alt="" className="w-[15rem] h-[13rem] ml-[6rem]" /> </p>}
        {selectedSection === "Identifying knowledge gaps and making a bridge" && <p className="text-xs lg:text-sm">The AI assistant can identify areas where you may need additional help or clarification, helping you focus your studying efforts more effectively. Gone are the days of needing to understand fully what you are reading before you can start building upon it. The AI will bridge the understanding gap for you and help you achieve your end result. <img src={bridge} alt="" className="w-[22rem] h-[14rem] ml-[2rem]" /></p>}
        {selectedSection === "Making sense of large notes" && <p className="text-xs lg:text-sm">The AI assistant can help you make sense of large pieces of information by identifying key concepts and providing examples or explanations. Additionally, the AI assistant can answer questions based on the notes, helping you better understand the material and apply it to real-world scenarios. It can also summerize and site exactly where its getting its answers from. So you can trust the answers its giving you. <img src={note} alt="" className="w-[19rem] h-[17rem] ml-[4rem] mt-[.7rem]" /></p>}
        {selectedSection === "Generating study questions" && <p className="text-xs lg:text-sm">The AI assistant can generate study questions at any difficulty based on the notes, helping you test your knowledge and prepare for tests. <img src={chatbot} alt="" className="w-[19rem] h-[17rem] ml-[4rem] mt-[3rem]" /></p>}
      </div>
      <div className="lg:w-1/2 pl-[1rem]lg:pl-[3rem] pr-[1rem] lg:pr-1 ml-[1rem] sm:mr-[1rem] text-sm">
        <h2 className="text-3xl font-Roboto font-bold mb-4">Use Cases</h2>
        <ul className="flex flex-col space-y-5 text-xs">
          <li
            className={`cursor-pointer  border border-gray-300 rounded-md p-2 hover:bg-dark-purple ${
              selectedSection === "Being your one stop knowledge base" ? "font-bold" : ""
            }`}
            onMouseEnter={() => handleMouseEnter("Being your one stop knowledge base")}
            style={{
              transition: "transform 0.2s ease-in-out",
              transform: selectedSection === "Being your one stop knowledge base" ? "scale(1.05)" : "",
              fontSize: "1rem",
              lineHeight: "1.5rem",
            }}
          >
            <div className="p-2">
              <h3 className="text-xl font-Roboto font-bold mb-2">Being your one stop knowledge base:</h3>
              <p className="text-xs lg:text-sm">This note taking app is an all-in-one solution for storing and organizing your ever-growing knowledgebase..</p>
            </div>
          </li>
          <li
            className={`cursor-pointer border border-gray-300 rounded-md p-2 hover:bg-dark-purple ${
              selectedSection === "Identifying knowledge gaps and making a bridge" ? "font-bold" : ""
            }`}
            onMouseEnter={() => handleMouseEnter("Identifying knowledge gaps and making a bridge")}
            style={{
              transition: "transform 0.2s ease-in-out",
              transform: selectedSection === "Identifying knowledge gaps and making a bridge" ? "scale(1.05)" : "",
              fontSize: "1rem",
              lineHeight: "2.5rem",
            }}
          >
            <div className="p-2">
              <h3 className="text-lg font-Roboto font-bold mb-2">Identifying knowledge gaps and making a bridge: </h3>
              <p className="text-xs lg:text-sm">The AI assistant can identify areas where...</p>
            </div>
          </li>
          <li
            className={`cursor-pointer border border-gray-300 rounded-md p-2 hover:bg-dark-purple ${
              selectedSection === "Making sense of large notes" ? "font-bold" : ""
            }`}
            onMouseEnter={() => handleMouseEnter("Making sense of large notes")}
            style={{
              transition: "transform 0.2s ease-in-out",
              transform: selectedSection === "Making sense of large notes" ? "scale(1.05)" : "",
              fontSize: "1rem",
              lineHeight: "1.5rem",
            }}
          >
            <div className="p-2">
              <h3 className="text-lg font-Roboto font-bold mb-2">Making sense of large notes: </h3>
              <p className="text-xs lg:text-sm">The AI assistant can help students or researchers make sense of large notes...</p>
            </div>
          </li>
          <li
            className={`cursor-pointer border border-gray-300 rounded-md p-2 hover:bg-dark-purple ${
              selectedSection === "Generating study questions" ? "font-bold" : ""
            }`}
            onMouseEnter={() => handleMouseEnter("Generating study questions")}
            style={{
              transition: "transform 0.2s ease-in-out",
              transform: selectedSection === "Generating study questions" ? "scale(1.05)" : "",
              fontSize: "1rem",
              lineHeight: "1.5rem",
            }}
          >
            <div className="p-2">
              <h3 className="text-lg font-Roboto font-bold mb-2">Generating study questions:</h3>
              <p className="text-xs lg:text-sm">The AI assistant can generate study questions...</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default UseCase;
