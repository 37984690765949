// @ts-nocheck
import { client } from "./supabaseClient.ts";

const getformattedPage = async (pageId) => {
  try {
    const result = await client
      .from("formatted_page")
      .select("*")
      .eq("page_id", pageId);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export default getformattedPage;
