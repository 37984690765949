// @ts-nocheck
import React, { useEffect, useState } from "react";
import getformatedPage from "../../utils/getPages.ts";
import { useNavigate } from "react-router-dom";
import { client } from "../../utils/supabaseClient.ts";
import useContextMenuPages from "../../utils/hooks/useContextMenuPages.ts";
import useContextMenuFolders from "../../utils/hooks/useContextMenuFolders.ts";
import userSquare from "../../assets/UserSquare.png";
import fileText from "../../assets/FileText.png";
import plus from "../../assets/Plus.png";
import "./LeftSideBar.css";
import DiscordLogoDL from "../../assets/DiscordLogoDL.svg";
import threeDots from "../../assets/icons/threeDots.svg";

export default function Sidebar({
  getDigitalBrainPages,
  loadData,
  filledFolders,
  setCurrentPageId,
  setPageSections,
  user,
  digitalBrainId,
  setActivePage,
  handleCreatePageClick,
  handleCreateFolderClick,
  isOpen,
  getFolders,
  setIsOpen,
  dataLoading,
  setDataLoading,
}) {
  const navigate = useNavigate();
  const defaultUserName = user?.email.split("@")[0];
  const { pagesClicked, setPagesClicked, pagesPoints, setPagesPoints } =
    useContextMenuPages();
  const { foldersClicked, setFoldersClicked, folderPoints, setFolderPoints } =
    useContextMenuFolders();
  const [pagesContextMenuActive, setPagesContextMenuActive] = useState(false);
  const [pagesEditMode, setPagesEditMode] = useState(false);
  const [foldersEditMode, setFoldersEditMode] = useState(false);
  const [lastClickedPageIds, setLastClickedPageIds] = useState(new Set());
  const [folderId, setFolderId] = useState(0);
  const [pageId, setPageId] = useState(0);
  const [folderHovered, setFolderHovered] = useState(false);
  const [currentFolderId, setCurrentFolderId] = useState(0);
  const [threeDotsClicked, setThreeDotsClicked] = useState(false);
  const [pageHovered, setPageHovered] = useState(false);
  const [threeDotsClickedPage, setThreeDotsClickedPage] = useState(false);
  const [threeDotsPageId, setThreeDotsPageId] = useState(0);
  const [threeDotsFolderId, setThreeDotsFolderId] = useState(0);




  const handlePageClick = async (page, event) => {

    // Add the current clicked element's ID to the Set
    if (pagesEditMode === true) {
      return;
    }


    // setDataLoading(true);
    const formatedPage = await getformatedPage(page.id);
    if (formatedPage.data?.length > 0) {
      setPageSections(formatedPage);
    } else {
      setPageSections([]);
    }
    if (pagesContextMenuActive === true) {
      return;
    } else {
      setCurrentPageId(page.id);
      navigate(
        `/${defaultUserName}/digital-brain/${digitalBrainId}/page/${page.id}`
      );
      setActivePage(true);




      // Remove the Tailwind classes from the previously clicked elements
      lastClickedPageIds.forEach((lastClickedPageId) => {
        const lastClickedElement = document.getElementById(`page-${lastClickedPageId}`);


        if (lastClickedPageIds.has(lastClickedPageId)) {
          lastClickedElement.classList.remove("bg-opacity-70", "bg-dark-purple", "rounded-[3px]", "border-[.3px]");
          lastClickedPageIds.delete(page.id);
        }
      });

      // Add the Tailwind classes to the clicked element
      const clickedElement = event.target.closest("div");
      clickedElement.classList.add("bg-opacity-70", "bg-dark-purple", "rounded-[3px]", "border-[.3px]");
      lastClickedPageIds.add(page.id);


    }
  };

  const handlePageDelete = async (pageId) => {
    const { data, error } = await client
      .from("page")
      .delete()
      .match({ id: pageId });
    if (error) {
      console.log(error);
    } else {
      getDigitalBrainPages();
      navigate(`/${defaultUserName}/digital-brain/${digitalBrainId}`);
      setActivePage(false);
    }
    setPagesContextMenuActive(false);
  };

  const handleFolderDelete = async (folderId) => {
    setDataLoading(true);
    const { data: pageData, error: pageError } = await client
      .from("page")
      .select("*")
      .eq("folder_id", folderId);
    if (pageError) {
      console.log(pageError);
      setDataLoading(false);
    }
    if (pageData.length === 0) {
      const { data: folderData, error: folderError } = await client
        .from("folder")
        .delete()
        .match({ id: folderId });
      if (folderError) {
        setDataLoading(false);
        console.log(folderError);
      } else {
        getFolders();
        setDataLoading(false);
      }
    } else {
      alert("Folder is not empty! Please delete all pages in folder first.");
      setDataLoading(false);
    }
  };

  const handlePageRename = async (pageId, newPageName) => {
    const { data: pageData, error: pageError } = await client
      .from("page")
      .update({ title: newPageName })
      .match({ id: pageId });
    if (pageError) {
      console.log(pageError);
    } else {
      setPagesContextMenuActive(false);
      getDigitalBrainPages();
    }
  };

  const handleFolderRename = async (folderId, newFolderName) => {
    const { data: folderData, error: folderError } = await client
      .from("folder")
      .update({ title: newFolderName })
      .match({ id: folderId });
    if (folderError) {
      console.log(folderError);
    } else {
      loadData();
    }
  };

  // when page rename is clicked in context menu this function is called setting turning the page.title into an input field to rename the page
  const handlePageRenameClick = (pageId) => {
    setPagesEditMode(true);
    const page = document.getElementById(pageId);
    const pageName = document.getElementById(`${pageId}-name`);
    const pageRename = document.getElementById(`${pageId}-rename`);
    pageName.style.display = "none";
    pageRename.style.display = "block";
    setPagesContextMenuActive(false);
  };

  // if pagesEditMode is true and the user clicks out of the input field or hits enter, the page title is updated and the input field is hidden
  const handlePageRenameBlur = async (pageId, newPageName, pageTitle) => {
    if (newPageName === "") {
      alert("Page name cannot be empty!");
      return;
    }
    if (newPageName === pageTitle) {
      setPagesEditMode(false);
      setPagesContextMenuActive(false);
      return;
    }
    await handlePageRename(pageId, newPageName);
    setPagesEditMode(false);
    const page = document.getElementById(pageId);
    const pageName = document.getElementById(`${pageId}-name`);
    const pageRename = document.getElementById(`${pageId}-rename`);
    pageName.style.display = "block";
    pageRename.style.display = "none";
  };

  const handleFolderRenameClick = (folderId) => {
    setFoldersEditMode(true);
    setFolderId(folderId);
    const folder = document.getElementById(folderId);
    const folderName = document.getElementById(`${folderId}-name`);
    const folderRename = document.getElementById(`${folderId}-rename`);
    folderName.style.display = "none";
    folderRename.style.display = "block";
  };

  const handleFolderRenameBlur = async (
    folderId,
    newFolderName,
    folderTitle
  ) => {
    if (newFolderName === "") {
      alert("Folder name cannot be empty!");
      return;
    }
    if (newFolderName === folderTitle) {
      setFoldersEditMode(false);
      return;
    }
    await handleFolderRename(folderId, newFolderName);
    setFoldersEditMode(false);
    const folder = document.getElementById(folderId);
    const folderName = document.getElementById(`${folderId}-name`);
    const folderRename = document.getElementById(`${folderId}-rename`);
    folderName.style.display = "block";
    folderRename.style.display = "none";
  };

  useEffect(() => {
    getFolders()

  }, [foldersEditMode]);

  const handleFolderHover = (e, folderId) => {
    setFolderHovered(true);
    setFolderId(folderId);
  }

  const handleFolderThreeDotsClick = (event, folderId) => {

    event.preventDefault();
    event.stopPropagation();
    setThreeDotsClicked(true);
    setThreeDotsFolderId(folderId);
    setFolderPoints({
      x: event.pageX,
      y: event.pageY,
    });


  };

  const handlePageThreeDotsClick = (event, pageId) => {
    event.preventDefault();
    event.stopPropagation();
    setThreeDotsClickedPage(true);
    setThreeDotsPageId(pageId);
    setPagesPoints({
      x: event.pageX,
      y: event.pageY,
    });
  }

  const handlePageHovered = (e, pageId) => {
    setPageHovered(true);
    setPageId(pageId);
  }






  return (
    <div className={isOpen ? "side-bar shrink" : "sidebar-normal"}>
      <div>
        {filledFolders?.map((folder, i) => (
          <div key={folder.id} className="flex flex-col">
            <div
              onContextMenu={(e) => {
                e.preventDefault();
                setFoldersClicked(true);
                setFolderId(folder.id);
                setFolderPoints({
                  x: e.pageX,
                  y: e.pageY,
                });
              }}
              onMouseEnter={(e) => handleFolderHover(e, folder.id)}
              onMouseLeave={() => setFolderHovered(false)}
              className="flex justify-between"
            >
              <div
              id={`folder-${folder.id}`}
              className="w-full">
                {foldersEditMode === false || threeDotsFolderId !== folder.id ? (
                  <h3 className="truncate pr-[2rem]">{folder.title}</h3>
                ) : (
                  <input
                    id={`${folder.id}-rename`}
                    type="text"
                    className="bg-primary border border-[.1px] text-text-color w-full focus:outline-none opacity-80"
                    defaultValue={folder.title}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        const inputElement = e.target as HTMLInputElement;
                        inputElement.blur();
                      }
                    }}
                    onBlur={(e) => {
                      handleFolderRenameBlur(folder.id, e.target.value, folder.title);
                    }}
                  />
                )}
              </div>

              {/* {foldersClicked && folderId === folder.id && (
                <div
                  style={{
                    position: "absolute",
                    top: folderPoints.y,
                    left: folderPoints.x,
                    backgroundColor: "#1C1C1C",
                    color: "#F5F5F5",
                    border: ".1px solid #F5F5F5",
                    width: "10rem",
                    zIndex: 100,
                  }}
                  onMouseOver={(event) => {
                    (event.target as HTMLElement).style.backgroundColor =
                      "#4C2586";
                  }}
                  onMouseOut={(event) => {
                    (event.target as HTMLElement).style.backgroundColor =
                      "#1C1C1C";
                  }}
                  className="opacity-80"
                >
                  
                  <button
                    onClick={() => handleFolderRenameClick(folder.id)}
                    key={folder.id}
                    className="block p-[5px] w-full text-sm text-left pl-4"
                  >
                    Rename
                  </button>
                  <button
                    onClick={() => handleCreatePageClick(folder.id)}
                    key={folder.id}
                    className="block p-[5px] w-full text-sm text-left pl-4"
                  >
                    Create Page
                  </button>
                  <button
                    onClick={() => handleFolderDelete(folder.id)}
                    key={folder.id}
                    className=" block p-[5px] w-full text-sm text-left pl-4"
                  >
                    Delete
                  </button>
                </div>
              )} */}
              {filledFolders?.length > 0 &&
                filledFolders?.[0] !== "Nothing to see here!" && folderHovered && folderId === folder.id && (
                  <>
                    <button
                      onClick={(event) => handleFolderThreeDotsClick(event, folder.id)}
                      key={folder.id}
                      className="absolute right-0 mt-[.6rem] mr-5"
                    >
                      <img src={threeDots} alt="ThreeDots icon" />
                    </button>


                  </>
                )}
              {threeDotsClicked && folderId === folder.id && (
                <div
                  style={{
                    position: "absolute",
                    top: folderPoints.y,
                    left: folderPoints.x,
                    backgroundColor: "#1C1C1C",
                    color: "#F5F5F5",
                    border: ".1px solid #F5F5F5",
                    width: "10rem",
                    zIndex: 100,
                  }}
                  onMouseOver={(event) => {
                    (event.target as HTMLElement).style.backgroundColor =
                      "#4C2586";
                  }}
                  onMouseOut={(event) => {
                    (event.target as HTMLElement).style.backgroundColor =
                      "#1C1C1C";
                  }}
                  onMouseLeave={() => setThreeDotsClicked(false)}
                  className="opacity-80"
                >
                  
                  <button
                    onClick={() => handleFolderRenameClick(folder.id)}
                    key={folder.id}
                    className="block p-[5px] w-full text-sm text-left pl-4"
                  >
                    Rename
                  </button>
                  <button
                    onClick={() => handleCreatePageClick(folder.id)}
                    key={folder.id}
                    className="block p-[5px] w-full text-sm text-left pl-4"
                  >
                    Create Page
                  </button>
                  <button
                    onClick={() => handleFolderDelete(folder.id)}
                    key={folder.id}
                    className=" block p-[5px] w-full text-sm text-left pl-4"
                  >
                    Delete
                  </button>
                </div>
              )}

            </div>
            {folder?.pages?.map((page, i) => (
              <div
                key={page.id}
                onContextMenu={(e) => {
                  e.preventDefault();
                  setPagesClicked(true);
                  setPagesPoints({
                    x: e.pageX,
                    y: e.pageY,
                  });
                }}
                onMouseEnter={(e) => handlePageHovered(e, page.id)}
                onMouseLeave={() => setPageHovered(false)}
              >
                <div
                  id={`page-${page.id}`}
                  className="cursor-pointer flex hover:bg-dark-purple hover:bg-opacity-30 hover:border-[.1px] rounded-md"
                  onClick={(event) => handlePageClick(page, event)}
                  key={page.id}
                >
                  <img
                    src={fileText}
                    alt="File icon"
                    className="w-5 h-5 mr-2 ml-5"
                  />
                  {pagesEditMode === false || threeDotsPageId !== page.id ? (
                    <h4 className="truncate pr-[2rem]">{page.title}</h4>
                  ) : (
                    <input
                      id={`${page.id}-rename`}
                      type="text"
                      className="bg-primary border-[.1px] text-text-color w-1/2 focus:outline-none opacity-80"
                      defaultValue={page.title}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          const inputElement = e.target as HTMLInputElement;
                          inputElement.blur();
                        }
                      }}
                      onBlur={(e) => {
                        handlePageRenameBlur(page.id, e.target.value, page.title);
                      }}
                    />
                  )}
                  {/* {pagesClicked && pageId === page.id && (
                    <div
                      style={{
                        position: "absolute",
                        top: pagesPoints.y,
                        left: pagesPoints.x,
                        backgroundColor: "#1C1C1C",
                        color: "#F5F5F5",
                        border: ".1px solid #F5F5F5",
                        width: "10rem",
                        zIndex: 100,
                      }}
                      onMouseOver={(event) => {
                        setPagesContextMenuActive(true);
                        (event.target as HTMLElement).style.backgroundColor =
                          "#4C2586";
                      }}
                      onMouseOut={(event) => {
                        setPagesContextMenuActive(false);
                        (event.target as HTMLElement).style.backgroundColor =
                          "#1C1C1C";
                      }}
                      className="opacity-80"
                    >
                      
                      <button
                        onClick={() => handlePageRenameClick(page.id)}
                        key={page.id}
                        className="block p-[5px] w-full text-sm bg-opacity-90 text-left pl-4"
                      >
                        Rename
                      </button>
                      <button
                        onClick={() => handlePageDelete(page.id)}
                        key={page.id}
                        className="block p-[5px] w-full text-sm bg-opacity-90 text-left pl-4"
                      >
                        Delete
                      </button>
                    </div>

                  )} */}
                  {pageHovered && pageId === page.id && (
                    <>
                      <button
                        onClick={(event) => handlePageThreeDotsClick(event, page.id)}
                        key={page.id}
                        className="absolute right-0 mt-[.6rem] mr-5"
                      >
                        <img src={threeDots} alt="ThreeDots icon" />
                      </button>
                    </>
                  )}
                  {threeDotsClickedPage && pageId === page.id && (
                    <div
                      style={{
                        position: "absolute",
                        top: pagesPoints.y,
                        left: pagesPoints.x,
                        backgroundColor: "#1C1C1C",
                        color: "#F5F5F5",
                        border: ".1px solid #F5F5F5",
                        width: "10rem",
                        zIndex: 100,
                      }}
                      onMouseOver={(event) => {
                        setPagesContextMenuActive(true);
                        (event.target as HTMLElement).style.backgroundColor =
                          "#4C2586";
                      }}
                      onMouseOut={(event) => {
                        setPagesContextMenuActive(false);
                        (event.target as HTMLElement).style.backgroundColor =
                          "#1C1C1C";
                      }}
                      className="opacity-80"
                      onMouseLeave={() => setThreeDotsClickedPage(false)}
                    >
                      
                      <button
                        onClick={() => handlePageRenameClick(page.id)}
                        key={page.id}
                        className="block p-[5px] w-full text-sm bg-opacity-90 text-left pl-4"
                      >
                        Rename
                      </button>
                      <button
                        onClick={() => handlePageDelete(page.id)}
                        key={page.id}
                        className="block p-[5px] w-full text-sm bg-opacity-90 text-left pl-4"
                      >
                        Delete
                      </button>
                    </div>

                  )}

                </div>


              </div>
            ))}
          </div>
        ))}
      </div>
      <div style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
        <div className="border-t w-full mb-2"></div>
        <div className="flex p-1 mb-2">
          <img src={userSquare} alt="User icon" className="w-6 h-6 mr-2" />
          <a href={`/${defaultUserName}`} className="text-text-color">
            {defaultUserName}
          </a>

        </div>
        <div className="absolute bottom-12 right-2">
          <a href="https://discord.gg/ChaUXkMceq" target="_blank" >
            <img src={DiscordLogoDL} />
          </a>
        </div>
      </div>
    </div>
  );
}
