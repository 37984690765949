import { useState, useEffect } from "react";


const useContextMenuDigitalBrain = () => {
    const [digitalBrainClicked, setDigitalBrainClicked] = useState(false);
    const [digitalBrainPoints, setDigitalBrainPoints] = useState({
        x: 0,
        y: 0,
    });
    useEffect(() => {
        const handleClick = () => setDigitalBrainClicked(false);
        document.addEventListener("click", handleClick);
        return () => {
            document.removeEventListener("click", handleClick);
        };
    }, []);
    return {
        digitalBrainClicked,
        setDigitalBrainClicked,
        digitalBrainPoints,
        setDigitalBrainPoints,
    };
};
export default useContextMenuDigitalBrain;