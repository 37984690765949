
import React from "react";
import WaitlistConfirmation from "../../components/Waitlist/WaitListConfrimation.tsx";

export default function WaitlistConfirmationPage() {
    return (
        <>
        <WaitlistConfirmation />
        </>
    )
}