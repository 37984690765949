// @ts-nocheck
import React, { useEffect, useState } from "react";
import axios, { isCancel, AxiosError } from "axios";
import { getUsersAuth, betaCheck, waitlistCheck } from "../../utils/auth.ts";
import { useNavigate } from "react-router-dom";
import LogoutButton from "../../components/Logout/Logout.tsx";
import { client } from "../../utils/supabaseClient.ts";
import "./index.css";
import useContextMenuDigitalBrain from "../../utils/hooks/useContextMenuDigitalBrain.ts";
import threeDots from "../../assets/icons/threeDots.svg";
import Rive from '@rive-app/react-canvas';
import pageLoading from '../../assets/animations/pageLoading.riv';
import { createEmbeddings } from "../../utils/generate_embeddings.ts";

export default function UserAccount() {
  const [userId, setUserId] = useState(0);
  const [description, setDescription] = useState(null);
  const [name, setName] = useState(null);
  const [digitalBrains, setDigitalBrains] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [digitalBrainEditMode, setDigitalBrainEditMode] = useState(false);
  const [digitalBrainThreeDotsId, setDigitalBrainThreeDotsId] = useState(0);
  const [digitalBrainId, setDigitalBrainId] = useState(0);
  const [digitalBrainHover, setDigitalBrainHover] = useState(false);
  const [digitalBrainThreeDotsClicked, setDigitalBrainThreeDotsClicked] = useState(false);

  const { digitalBrainClicked, setDigitalBrainClicked, digitalBrainPoints, setDigitalBrainPoints } = useContextMenuDigitalBrain();

  useEffect(() => {
    getDigitalBrains();
  }, [digitalBrainEditMode]);

  const getDigitalBrains = async () => {

    const { data, error } = await client
      .from("digital_brain")
      .select("*")
      .eq("user_id", userId);

    if (data) {
      setDigitalBrains(data);
      setDataLoading(false);

    } else {
      // setDigitalBrains(["No Digital Brains Created"]);
      setDataLoading(false);

    }
    if (error) {
      console.log(error);

    }
  };

  useEffect(() => {

  }, [digitalBrains]);

  useEffect(() => {
    const getDigitalBrainsData = async () => {
      await getDigitalBrains();
    };
    getDigitalBrainsData();
  }, [dataLoading]);

  useEffect(() => {
    setDataLoading(true);
    const getUsers = async () => {
      const users = await getUsersAuth();
      const beta = await betaCheck();
      const waitlist = await waitlistCheck()



      if (users) {
        setIsAuthenticated(true);
      }
    };
    getUsers();
  }, []);

  useEffect(() => {
    const getUser = async () => {
      const {
        data: { session },
        error,
      } = await client.auth.getSession();
      const users = await getUsersAuth();
      if (error) {
        console.log(error);
      }
      if (session && users) {
        setIsAuthenticated(true);
        const { user } = session;
        const defaultUserName = user.email.split("@")[0];
        setUserId(user.id);
        setName(defaultUserName);
      } else {
        navigate("/login");
      }
    };
    getUser();
    if (isAuthenticated) {
      getDigitalBrains();

    }
  }, [isAuthenticated]);

  if (dataLoading) {
    return (
      <div className="flex h-screen text-text-color">
        <h1 className="m-auto"><Rive src={pageLoading} style={
          {
            width: '20.5rem',
            height: '20.5rem',
          }
        } /> </h1>
      </div>
    );
  } else if (!isAuthenticated) {
    return navigate("/");
  }

  // Create a Digital Brain
  const createDigitalBrain = async () => {
    try {
      setDataLoading(true);
      const { data, error } = await client
        .from("digital_brain")
        .upsert([{ name: "Unnamed Digital Brain", description: "No description provided", user_id: userId }])
        .select()

      const digitalBrainId = data[0].id

      if (data) {
        setDigitalBrains(data);
        setDataLoading(false);
      }

      const { data: folderData, folderError } = await client
        .from("folder")
        .upsert([{ title: "Getting Started", digital_brain_id: digitalBrainId }])
        .select()

      const { data: pagesData, pagesError } = await client
        .from("page")
        .upsert([{ title: "Welcome to your Digital Brain", folder_id: folderData[0].id, digital_brain_id: digitalBrainId }])
        .select()

      const text = "How do I use the digital brain?: First You want to create a folder. Then you want to create a page. To create a folder you will click the create folder button on the left side bar. Then to create a page you will click the 3 dots next to the new folder you created. Then click on the create page button. Then you can start adding content to your page. You can add text, images, videos, and links. It works just like most text editors. To make things look fancy try right clicking inside the text editor.\n\n What is the digital brain?: The digital brain is a place to store all of your knowledge. You can create folders and pages to organize your knowledge. You can also create links between pages to create a web of knowledge. This is your personal knowledge place for all your notes. That when you have a question you can come here and find the answer with you own personal ai assistant trained on those notes and knowledge.\n\n What kind of notes can I take with the digital brain?: Currently any kind of notes you want. If you want to copy video links or images you can. Even if you want to copy an entire webpage you can.\n\n What does the digital brain know?: you knows what you tell it. You is trained on your notes and knowledge. Meaning if you dont have a note on it then it wont have an answer. If your notes are wrong then it will give you a wrong answer. So make sure your notes are correct to what you want of the ai.\n\n How do I get started with the digital brain?: First You want to create a folder. Then you want to create a page. To create a folder you will click the create folder button on the left side bar. Then to create a page you will click the 3 dots next to the new folder you created. Then click on the create page button. Then you can start adding content to your page. \n\n Where do if find support?: Easy! You can find support on the discord. https://discord.gg/ChaUXkMceq "

    

     await createEmbeddings(digitalBrainId, pagesData[0].id, text)

     const {data: formattedPagesData, formattedPagesError} = await client
      .from("formatted_page")
      .upsert([{ page_id: pagesData[0].id, formatted_page: text }])
      .select()

      if (error) {
        console.log(error);
      }
    } catch (err) {
      if (isCancel(err)) {
        console.log("Request canceled", err.message);
      } else {
        console.log(err);
      }
    }
  };



  const handleDigitalBrainClick = (brainId) => {
    if (digitalBrainThreeDotsClicked) {
      return;
    }
    if (brainId === undefined) return;
    return () => navigate(`/${name}/digital-brain/${brainId}`);
  };

  const handleDigitalBrainRename = async (digitalBrainId, newDigitalBrainName) => {
    const { data: digitalBrainData, error: digitalBrainError } = await client
      .from("digital_brain")
      .update({ name: newDigitalBrainName })
      .match({ id: digitalBrainId });
    if (digitalBrainError) {
      console.log(digitalBrainError);
    } else {
    }
  };

  const handleDigitalBrainDelete = (brainId) => {
    if (brainId === undefined) return;
    return async () => {
      try {
        const { data, error } = await client
          .from("digital_brain")
          .delete()
          .eq("id", brainId)
          .single();

        const digitalBrainsData = await getDigitalBrains();


        if (digitalBrainsData) {
          setDigitalBrains(data);
          setDataLoading(true);
        } else {

        }
        if (error) {
          console.log(error);
        }
      } catch (err) {
        console.log(err);
      }
    };
  };

  const handleDigitalBrainRenameClick = (digitalBrainId) => {
    setDigitalBrainEditMode(true);
    const digitalBrain = document.getElementById(digitalBrainId);
    const digitalBrainName = document.getElementById(`${digitalBrainId}-name`);
    const digitalBrainRename = document.getElementById(`${digitalBrainId}-rename`);
    digitalBrainName.style.display = "none";
    digitalBrainRename.style.display = "block";
  };

  const handleDigitalBrainRenameBlur = async (
    digitalBrainId,
    newDigitalBrainName,
    digitalBrainTitle
  ) => {
    if (newDigitalBrainName === "") {
      alert("Folder name cannot be empty!");
      return;
    }
    if (newDigitalBrainName === digitalBrainTitle) {
      setDigitalBrainEditMode(false);
      return;
    }
    await handleDigitalBrainRename(digitalBrainId, newDigitalBrainName);
    setDigitalBrainEditMode(false);
    const digitalBrain = document.getElementById(digitalBrainId);
    const digitalBrainName = document.getElementById(`${digitalBrainId}-name`);
    const digitalBrainRename = document.getElementById(`${digitalBrainId}-rename`);
    digitalBrainName.style.display = "block";
    digitalBrainRename.style.display = "none";
  };

  const handleDigitalBrainThreeDotClick = (event, digitalBrainId) => {
    event.preventDefault();
    event.stopPropagation();
    setDigitalBrainThreeDotsId(digitalBrainId);
    setDigitalBrainThreeDotsClicked(true);
    setDigitalBrainPoints({
      x: event.pageX,
      y: event.pageY,
    });
  };

  const handleDigitalBrainHover = (e, digitalBrainId) => {
    e.preventDefault();
    setDigitalBrainId(digitalBrainId);
    setDigitalBrainHover(true);
  }


  return (
    !dataLoading && (
      <div className="digital-brain-wrapper">
        <div className="digital-brain-nav">
          <div className="user-account-title">
            <h1>User Dashboard</h1>
          </div>
          {!dataLoading && isAuthenticated && (
            <div>
              <LogoutButton />
            </div>
          )}
        </div>

        <div className="all-brains">
          <div>
            <h2 className="current-brains-title">Current Digital Brains</h2>
            <div className="current-brains-wrap">
              <div
                onContextMenu={(e) => {
                  e.preventDefault();
                  setDigitalBrainClicked(true);
                  setDigitalBrainPoints({
                    x: e.pageX,
                    y: e.pageY,
                  });
                }}

                className="flex justify-between flex-wrap"

              >

                {digitalBrains?.length > 0 &&
                  digitalBrains.map((brain, i) => (
                    <div
                      onMouseEnter={(e) => handleDigitalBrainHover(e, brain.id)}
                      onMouseLeave={() => setDigitalBrainHover(false)}

                    >
                      {digitalBrainClicked && digitalBrainId === brain.id && (
                        <div
                          style={{
                            position: "absolute",
                            top: digitalBrainPoints.y,
                            left: digitalBrainPoints.x,
                            backgroundColor: "#1C1C1C",
                            color: "#F5F5F5",
                            border: ".1px solid #F5F5F5",
                            width: "10rem",
                            zIndex: 100,
                          }}
                          onMouseOver={(event) => {
                            (event.target as HTMLElement).style.backgroundColor =
                              "#4C2586";
                          }}
                          onMouseOut={(event) => {
                            (event.target as HTMLElement).style.backgroundColor =
                              "#1C1C1C";
                          }}
                          className="opacity-80"


                        >
                          <button
                            onClick={() => handleDigitalBrainRenameClick(brain.id)}
                            key={i}
                            className="block p-[5px] w-full text-sm text-left pl-4"
                          >
                            Rename
                          </button>
                          <button
                            className="block p-[5px] w-full text-sm text-left pl-4"
                            onClick={handleDigitalBrainDelete(brain.id)}
                            key={brain.id}
                          >
                            Delete
                          </button>
                        </div>
                      )}


                      <div
                        onClick={digitalBrainEditMode ? undefined : handleDigitalBrainClick(brain.id)}
                        className="current-brains relative"
                        key={brain.id}
                      >
                        {digitalBrainEditMode === false || digitalBrainThreeDotsId !== brain.id ? (<h3 className="truncate pr-[1rem]" id={`${brain.id}-name`}>{brain.name}</h3>
                        ) : (
                          digitalBrainEditMode === true && (
                            <input
                              id={`${brain.id}-rename`}
                              type="text"
                              className="bg-primary border border-[.1px] text-text-color w-full focus:outline-none opacity-80"
                              defaultValue={brain.name}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  const inputElement = e.target as HTMLInputElement;
                                  inputElement.blur();
                                }
                              }}
                              onBlur={(e) => {
                                handleDigitalBrainRenameBlur(
                                  brain.id,
                                  e.target.value,
                                  brain.name
                                );
                              }}
                            />
                          )
                        )}
                        {digitalBrainHover && digitalBrainId === brain.id && (
                          <button
                            onClick={(event) => handleDigitalBrainThreeDotClick(event, brain.id)}
                            className="top-2 absolute right-2 w-[1rem] h-[1rem]">
                            <img src={threeDots} alt="three dots" />
                          </button>
                        )}
                        {digitalBrainThreeDotsClicked && digitalBrainId === brain.id && (
                          <div
                            style={{
                              position: "absolute",
                              top: '0',
                              right: '0',
                              backgroundColor: "#1C1C1C",
                              color: "#F5F5F5",
                              border: ".1px solid #F5F5F5",
                              width: "10rem",
                              zIndex: 100,
                            }}
                            onMouseOver={(event) => {
                              (event.target as HTMLElement).style.backgroundColor =
                                "#4C2586";
                            }}
                            onMouseOut={(event) => {
                              (event.target as HTMLElement).style.backgroundColor =
                                "#1C1C1C";
                            }}
                            className="opacity-80"
                            onMouseLeave={() => setDigitalBrainThreeDotsClicked(false)}
                          >
                            <button
                              onClick={() => handleDigitalBrainRenameClick(brain.id)}
                              key={brain.id}
                              className="block p-[5px] w-full text-sm text-left pl-4"
                            >
                              Rename
                            </button>
                            <button
                              className="block p-[5px] w-full text-sm text-left pl-4"
                              onClick={handleDigitalBrainDelete(brain.id)}
                              key={brain.id}
                            >
                              Delete
                            </button>
                          </div>
                        )}

                      </div>

                    </div>
                  ))}
              </div>
              <div>
                <button className="current-brains" onClick={createDigitalBrain}>
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
