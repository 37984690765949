// @ts-nocheck
import React, { useEffect, useState } from "react";

import logoName from "../../assets/logoName.png";
import { useNavigate } from "react-router-dom";
import { client } from "../../utils/supabaseClient.ts";
import "./index.css";
import UserAccount from "../../pages/UserAccount/index.tsx";

import LoginButton from "../Login/Login";
import LogoutButton from "../Logout/Logout.tsx";

function Navigation() {
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const [loggedOut, setLoggedOut] = useState(false);

  const handleLoginButton = async () => {
    navigate("/login");
  };


  const getUser = async () => {
    const {
      data: { user },
    } = await client.auth.getUser();

    console.log(user)


    if (user) {
      setUser(user);
    } else {
      setUser({});
    }
  };

  useEffect(() => {
    const getUserData = async () => {
      await getUser();
    };
    getUserData();
  }, []);



  const handleLogoutClick = async () => {
    await getUser();
    setLoggedOut(true);
    setUser({});
  }


  let loginLink;
  if (loggedOut) {
    loginLink = (
      <div className="right-nav">
        <LoginButton />
      </div>
    );
  }

  let logoutLink;
    logoutLink = (
      <div onClick={handleLogoutClick} className="right-nav">
        <LogoutButton />
      </div>
    );





  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    const defaultUserName = user?.email?.split("@")[0];
    navigate(`/${defaultUserName}`);
  };

  let getStarted;
  if (user) {
    getStarted = (
      <button
        type="button"
        className="call-to-action"
        onClick={handleLoginButton}
      >
        Get started
      </button>
    );
  } 



   let dashboard = (
    <button type="button" className="call-to-action" onClick={handleClick}>
      Dashboard
    </button>
  );


  return (
    <div className="nav-wrapper">
      <a href="/">
      <img src={logoName}></img>
      </a>
        <div className="right-nav">
          {user?.role === "authenticated" ? logoutLink : loginLink}
          {user?.role === "authenticated" ? dashboard : getStarted}
      </div>


    </div>
  );
}

export default Navigation;
