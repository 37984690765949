// @ts-nocheck
import { client } from "./supabaseClient.ts";

export const auth = async () => {
    const { data: { session }, error } = await client.auth.getSession()
      if (error) {
        console.log(error);
      }

      if (session) {
        return true;
        } else {
        return false;
        }
    }

export const getUsersAuth = async () => {
  const { data: { session }, error } = await client.auth.getSession()
      if (error) {
        console.log(error);
      }

      if (session) {
        return session.user;
        } else {
        return false;
        }
    }

    export const getNonAuthUser = async (email) => {
      const {data: users, error} = await client
      .from('user')
      .select('*')
      .eq('email', email)

      if (error) {
        console.log(error);
      }

      if (users) {
        return users[0];
      } else {
        return false;
      }
    }


    export const betaCheck = async () => {

      const authUser = await getUsersAuth()

      if (!authUser) {
        return false
      }

      const NonUserAuth = await getNonAuthUser(authUser?.email)

      if (NonUserAuth.beta_approved === null) {
        return false
      }
      else {
        return NonUserAuth.beta_approved
      }

    }

    export const waitlistCheck = async () => {
        
        const user = await getUsersAuth()
  
        const {data, error} = await client
        .from('waitlist')
        .select('email')
        .eq('email', user?.email)
  
        if (error) {
          console.log(error);
        }
  
        if (data) {
          return data;
        }
        else {
          return false;
        }
  
      }



