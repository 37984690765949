
import { ChatOpenAI } from "langchain/chat_models/openai";
import { OpenAIEmbeddings } from "langchain/embeddings/openai";
import { RecursiveCharacterTextSplitter } from "langchain/text_splitter";
import { MemoryVectorStore } from "langchain/vectorstores/memory";
import { createClient } from "@supabase/supabase-js";
import { RetrievalQAChain } from "langchain/chains";
import { Configuration, OpenAIApi} from "openai";






// Set up OpenAI API key
const openAiKey = process.env.REACT_APP_OPENAI_API_KEY;

// Set up Supabase credentials
const supabaseURL = process.env.REACT_APP_DB_URL;
const supabaseKey = process.env.REACT_APP_ANON_KEY;



const configuration = new Configuration({
  apiKey: openAiKey,
});

const openai = new OpenAIApi(configuration);




export const query = async (queryQuestion: string, digital_brain_id: number, page_ids:string[]) => {
    // create a chat model


    const chat = new ChatOpenAI({
      modelName: "gpt-3.5-turbo", // looking to switch to gpt-3.5-turbo-16k. Need testing on getting formatting to work on output.
      openAIApiKey: openAiKey,
    });
  
    // create a embeddings model
    const embeddings = new OpenAIEmbeddings({
      openAIApiKey: openAiKey,
    });

  
    // Set up Supabase client
    let client; 
    if (supabaseURL && supabaseKey) {
      client = createClient(supabaseURL, supabaseKey);
    }
  
    // embed query
    const embeddedQuery = await embeddings.embedQuery(queryQuestion);

    
  
    try {
      let searchResults;
      if(client){
        if (page_ids.length > 0) {
          // console.log("page_ids", page_ids)
          searchResults = await client
          .rpc('match_documents_pages', {
            query_embedding: embeddedQuery, // Pass the embedding you want to compare
            digital_brain_id, // Replace with the digital_brain_id you want to search
            page_ids: page_ids, // Pass an array of page_ids to match with
            match_count: 5, // Choose the number of matches
          })
          .then((res) => {
            return res.data;
          });
        } 
        else {
       searchResults = await client
        .rpc("match_documents", {
          query_embedding: embeddedQuery,
          match_count: 5,
          digital_brain_id,
        })
        .then((res) => {
          return res.data;
        });
      }

        // console.log("searchResults", searchResults)

  
      const searching = searchResults.map((result: { content: string }) => {
        return result.content;
      });

      // console.log(searchResults)

  
      const textSplitter = new RecursiveCharacterTextSplitter({
        chunkSize: 3000,
        ...{ overlapSize: 100 },
      });
      const docs = await textSplitter.createDocuments(searching);

      // console.log(docs)

  
      // Create a vector store from the documents.
      const vectorStore = await MemoryVectorStore.fromDocuments(
        docs,
        embeddings,
        {}
      );

  
      const chain = RetrievalQAChain.fromLLM(chat, vectorStore.asRetriever(20));

      const res = await chain.call({
        query: `Format the response into HTML with <br> for lines and make it nice and readable. Keep strictly to the above information to answer the question: ${queryQuestion}. \n\n  If the above information is not enough to answer the question, please say "I don't know. Please provide more notes on the subject. \n\n Refer to the context as "your notes"."`,
      });

      // console.log(res.text)

      

      // const htmlFormated = await openai.createChatCompletion({
      //   model: "gpt-3.5-turbo",
      //   messages: [
      //     {role: "system", content: "You take in text and respond with that text in HTML format. Leave out <html> and <body> tags."},
      //     {role: "user", content: `${res.text}`},
      //   ],
      // });

      // const fomrated = htmlFormated.data.choices[0].message?.content

      // console.log(fomrated)
      


      
      return { res };
    }
    } catch (error) {
      console.error(error);
    }
  };

  // query("Tell me about kyle.", 1);


  // TODO: This needs a refactor to make the time complexity better. Look into seeing if the queries can be done without having to make a vector store after pulling the data from the database.