// @ts-nocheck

import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { client } from "../../utils/supabaseClient.ts";
import { useParams } from "react-router-dom";
import Page from "./Page.tsx";
import axios from "axios";
import Sidebar from "./Sidebar.tsx";
import { getUsersAuth, betaCheck, waitlistCheck } from "../../utils/auth.ts";
import AskAI from "./AskAI.tsx";
import FullPageAskAI from "./FullPageAskAI.tsx";
import Rive from '@rive-app/react-canvas';
import pageLoading from '../../assets/animations/pageLoading.riv';

export default function DigitalBrain() {
  const [dataLoading, setDataLoading] = useState(true);
  const navigate = useNavigate();
  const [userId, setUserId] = useState(0);
  const [pages, setPages] = useState([]);
  const [folders, setFolders] = useState([]);
  const [currentPageId, setCurrentPageId] = useState(0);
  const [pageSections, setPageSections] = useState([]);
  const [activePage, setActivePage] = useState(false);
  const [filledFolders, setFilledFolders] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [question, setQuestion] = useState("");
  const [fullScreenOpen, setFullScreenOpen] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [questionSubmit, setQuestionSubmit] = useState(false)

  let digitalBrainId = useParams();
  digitalBrainId = Number(digitalBrainId.digitalBrainId);

  const [digitalBrains, setDigitalBrains] = useState([]);
  const [user, setUser] = useState(null);
  const defaultUserName = user?.email.split("@")[0];

  useEffect(() => {
    const getUsers = async () => {
      const users = await getUsersAuth();
      const beta = await betaCheck();
      const waitlist = await waitlistCheck()

      if (users) {
        setUser(users);
      }
    };
    getUsers();
  }, []);

  const getQuestion = async () => {
    try {
      await setQuestion(question);
    } catch (error) {
      console.log(error);
    }
  };


  const getDigitalBrains = async () => {
    try {
      const { data, error } = await client
        .from("digital_brain")
        .select("*")
        .eq("id", digitalBrainId);

      if (data?.length === 0) {
      } else {
        setDigitalBrains(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDigitalBrains();
  }, [digitalBrainId]);

  const getDigitalBrainPages = async () => {
    try {
      const result = await client
        .from("page")
        .select("*")
        .eq("digital_brain_id", digitalBrainId);

      if (result.data?.length > 0) {
        setPages(result.data);
      } else {
        setPages([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFolders = async () => {
    try {
      const result = await client
        .from("folder")
        .select("*")
        .eq("digital_brain_id", digitalBrainId);

      if (result?.data?.length > 0) {
        setFolders(result.data);
      } else {
        setFolders([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const loadData = async () => {
    try {
      if (digitalBrains?.length !== 0) {
        const filledFolders = folders.map((folder) => {
          folder.pages = pages.filter((page) => page.folder_id === folder.id);
          return folder;
        });

        if (filledFolders.length > 0) {
          await setFilledFolders(filledFolders);
          setDataLoading(false);
        } else {
          setFilledFolders([]);
          setDataLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadData();
  }, [digitalBrains, pages, folders]);

  useEffect(() => {
    getFolders();
  }, [digitalBrainId]);

  useEffect(() => {
    getDigitalBrainPages();
  }, [folders, digitalBrainId]);

  useEffect(() => {
    const getSectionsData = async () => {
      await getDigitalBrainPages();
    }
    getSectionsData();
    getFolders();
  }, [dataLoading]);

  const handleCreatePageClick = async (folderId) => {
    try {

      const { data, error } = await client
        .from("page")
        .insert([{ digital_brain_id: digitalBrainId, title: "Untitled Page", folder_id: folderId }]);

      await getDigitalBrainPages();
      setPages(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCreateFolderClick = async () => {
    setDataLoading(true);
    try {
      const { data, error } = await client
        .from("folder")
        .insert([{ digital_brain_id: digitalBrainId, title: "Untitled Folder" }]);
      await setFolders(data);
      setDataLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSuggestionClick = (suggestion: string) => {
    setSelectedSuggestion(suggestion);
  };

  return (
    <>
      {!dataLoading && (
        <div className="flex h-screen w-full text-text-color">
          <div className="w-[15rem] h-screen bg-sidebar-grey inset-y-0 left-0 border-r-[.5px]">
            <div className="p-4 reletive h-[100%]" style={{ position: "relative", height: "100%" }}>
              
              <button
                className="bg-sidbar-grey border-[.1px] hover:bg-dark-purple text-white font-bold py-2 px-6 rounded focus:outline-none focus:shadow-outline w-full sm:py-[.7rem] sm:px-4 mb-8"
                onClick={handleCreateFolderClick}
              >
                New Folder
              </button>
              <div className="border-t mb-5"> </div>
              <Sidebar
                dataLoading={dataLoading}
                setDataLoading={setDataLoading}
                getFolders={getFolders}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                handleCreateFolderClick={handleCreateFolderClick}
                handleCreatePageClick={handleCreatePageClick}
                getDigitalBrainPages={getDigitalBrainPages}
                loadData={loadData}
                filledFolders={filledFolders}
                setCurrentPageId={setCurrentPageId}
                setPageSections={setPageSections}
                user={user}
                digitalBrainId={digitalBrainId}
                setActivePage={setActivePage}
              />
            </div>
          </div>
          <div className="relative w-full h-full">
            {activePage && (
              <Page
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                pages={pages}
                currentPageId={currentPageId}
                setCurrentPageId={setCurrentPageId}
                pageSections={pageSections}
                digitalBrainId={digitalBrainId}
                question={question}
                setQuestion={setQuestion}
                loading={loading}
                setLoading={setLoading}
                questionSubmit={questionSubmit}
                setQuestionSubmit={setQuestionSubmit}
                setPages={setPages}
                user={user}
                dataLoading={dataLoading}
                setDataLoading={setDataLoading}
              />
            )}
            {fullScreenOpen && !activePage && (
              <FullPageAskAI
                answers={answers}
                setAnswers={setAnswers}
                question={question}
                setQuestion={setQuestion}
                fullScreenOpen={fullScreenOpen}
                setFullScreenOpen={setFullScreenOpen}
                loading={loading}
                setLoading={setLoading}
                questionSubmit={questionSubmit}
                setQuestionSubmit={setQuestionSubmit}
              />
            )}

            <div className="grid h-screen">
              <div className="flex items-center justify-center ">
                {!activePage && !fullScreenOpen && (
                  <div className="flex flex-col items-center justify-center -mt-[2rem]">
                    <h1 className="text-4xl font-bold text-text-color pb-5">
                      {`Welcome, ${defaultUserName}`}
                    </h1>
                    <p className="text-l text-text-color pb-10">
                      Ask your notes anything
                    </p>
                    <div className="ml-[2rem] mb-[2rem]">
                      <AskAI
                        selectedSuggestion={selectedSuggestion}
                        answers={answers}
                        setAnswers={setAnswers}
                        question={question}
                        setQuestion={setQuestion}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        setFullScreenOpen={setFullScreenOpen}
                        fullScreenOpen={fullScreenOpen}
                        loading={loading}
                        setLoading={setLoading}
                        questionSubmit={questionSubmit}
                        setQuestionSubmit={setQuestionSubmit}
                      />
                    </div>
                    <div className="grid grid-cols-3 grid-rows-2 gap-4">
                      <div
                        className="bg-primary p-4 cursor-pointer border-[.1px] rounded w-[15rem] h-[7rem] flex items-center text-center hover:bg-dark-purple"
                        onClick={() => handleSuggestionClick("How do I use the digital brain?")}
                      >
                        <p className="text-md font-bold">How do I use the digital brain?</p>
                      </div>
                      <div
                        className="bg-primary p-4 cursor-pointer border-[.1px] rounded w-[15rem] h-[7rem] flex items-center text-center hover:bg-dark-purple"
                        onClick={() => handleSuggestionClick("What is the Digital Brain?")}
                      >
                        <p className="text-lg font-bold">What is the Digital Brain?</p>
                      </div>
                      <div
                        className="bg-primary p-4 cursor-pointer border-[.1px] rounded w-[15rem] h-[7rem] flex items-center text-center hover:bg-dark-purple"
                        onClick={() => handleSuggestionClick("What kind of notes can I take in the Digital Brain?")}
                      >
                        <p className="text-lg font-bold">What kind of notes can I take in the Digital Brain?</p>
                      </div>
                      <div
                        className="bg-primary p-4 cursor-pointer border-[.1px] rounded w-[15rem] h-[7rem] flex items-center text-center hover:bg-dark-purple"
                        onClick={() => handleSuggestionClick("What does the Digital Brain know?")}
                      >
                        <p className="text-lg font-bold">What does the Digital Brain know?</p>
                      </div>
                      <div
                        className="bg-primary p-4 cursor-pointer border-[.1px] rounded w-[15rem] h-[7rem] flex items-center text-center hover:bg-dark-purple"
                        onClick={() => handleSuggestionClick("How do I get started in the Digital Brain?")}
                      >
                        <p className="text-lg font-bold">How do I get started in the Digital Brain?</p>
                      </div>
                      <div
                        className="bg-primary p-4 cursor-pointer border-[.1px] rounded w-[15rem] h-[7rem] flex items-center text-center hover:bg-dark-purple"
                        onClick={() => handleSuggestionClick("Where do I find support?")}
                      >
                        <p className="text-lg font-bold">Where do I find support?</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {dataLoading && (
        <div className="flex h-screen text-text-color">
          <h1 className="m-auto"><Rive src={pageLoading} style={
                {
                  width: '20.5rem',
                  height: '20.5rem',
                }
              } /></h1>
        </div>
      )}
    </>
  );
}
