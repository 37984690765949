import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import { createClient } from "@supabase/supabase-js";

const supabaseURL: any = process.env.REACT_APP_DB_URL;
const supabaseKey: any = process.env.REACT_APP_ANON_KEY;

let client = createClient(supabaseURL, supabaseKey);

export default function Waitlist() {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState<string[]>([]);
    const [discord, setDiscord] = useState("");

    const handleJoinWaitlist = async () => {
        let newErrors: string[] = [];
        if (!name) {
            newErrors.push("Please enter your name");
        }
        if (!email.includes("@") || !email.includes(".") || !email) {
            newErrors.push("Please enter a valid email");
        }
        if (!discord) {
            newErrors.push("Please let us know if you have a Discord account");
        }
        if (newErrors.length > 0) {
            setErrors(newErrors);
            return;
        }
        if (name && email && email.includes("@") && email.includes(".") && discord){
            try {
                await client
                    .from("waitlist")
                    .insert([{ name: name, email: email, discord: discord }])
                    .then(() => {
                        navigate("/waitlist/confirm")
                    })
            } catch (error) {
                console.log(error);
            }

        }
    }

    return (
        <>
            <div
                className="
                container
                min-h-screen
                mx-auto
                px-8
                flex flex-col
                items-center
                justify-center
                space-y-10
                lg:flex-row lg:px-0 lg:space-x-8 lg:pb-10
                xl:space-x-24
                pb-[20rem]
                mt-[1rem]
                w-full
                min-w-[20rem]
                text-text-color
            "
            >
                {/* <!-- top/left section --> */}
                <div className="flex flex-col space-y-2 min-w-[20rem]">
                    {/* <!-- todo: icon -->

                <!-- page title --> */}
                    <h1 className="font-bold text-4xl lg:text-5xl pb-5">
                        Digital Brain
                    </h1>

                    {/* <!-- page description --> */}
                    <p className="opacity-75 text-xl max-w-md lg:text-xl">
                        Your Personal ChatGPT Assistant, Powered by Your Notes
                    </p>
                </div>

                {/* <!-- bottom/right section --> */}
                <div className="flex w-full max-w-md lg:max-w-lg">
                    {/* <!-- card --> */}
                    <div
                        className="
                        text-black
                        w-full
                        flex flex-col
                        items-center
                        px-8
                        py-10
                        rounded-2xl
                        z-10
                        shadow-2xl shadow-black/10
                        md:px-14
                        lg:py-16
                        selection:bg-black selection:text-white
                        min-w-[20rem]
                        bg-sidebar-grey
                    "
                    >
                        <h3 className="font-bold text-3xl mb-10 lg:text-4xl">
                            Join the Waitlist
                        </h3>
                        <form
                            className="flex flex-col items-center w-full mb-[1rem] mt-[-1rem] pb-5"
                            onSubmit={(e) => e.preventDefault()}
                        >
                            {/* <!-- error --> */}
                            {errors.length > 0 && (
                                errors.map((err, i) => (
                                    <p key={i} className="text-red-500 text-sm mb-2">
                                        {err}
                                    </p>
                                ))
                            )}
                            <div
                                className="flex flex-col space-y-4 w-full font-semibold mb-5 mt-[1rem]"
                            >
                                <input
                                    type="text"
                                    placeholder="Name"
                                    className="
                                    transition-colors
                                    w-full
                                    bg-sidebar-grey
                                    px-5
                                    py-3
                                    rounded-lg
                                    border-[3px] border-slate-200
                                    focus:outline-none focus:border-black
                                "
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <input
                                    type="text"
                                    placeholder="Email"
                                    className="
                                    transition-colors
                                    w-full
                                    bg-sidebar-grey
                                    px-5
                                    py-3
                                    rounded-lg
                                    border-[3px] border-slate-200
                                    focus:outline-none focus:border-black
                                "
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <select value={discord} onChange={(e) => {
                                setDiscord(e.target.value)
                            }} className="w-full bg-sidebar-grey px-5 py-3 rounded-lg border-[3px] border-slate-200 focus:outline-none focus:border-black  mb-5 text-text-color">
                                <option value="" disabled selected>
                                    Do you have a Discord account?
                                </option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                            <button
                                type="submit"
                                className="
                                transition-all
                                flex
                                items-center
                                justify-center
                                space-x-1
                                px-5
                                py-3
                                bg-black
                                text-white
                                rounded-lg
                                w-full
                                ring-transparent ring-offset-4 ring
                                active:ring-black
                                hover:bg-dark-purple
                            "
                                onClick={handleJoinWaitlist}
                            >
                                <span>Join waitlist</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    className="w-5 h-5"
                                >
                                    <path
                                        fill-rule="evenodd"
                                        d="M3 10a.75.75 0 01.75-.75h10.638L10.23 5.29a.75.75 0 111.04-1.08l5.5 5.25a.75.75 0 010 1.08l-5.5 5.25a.75.75 0 11-1.04-1.08l4.158-3.96H3.75A.75.75 0 013 10z"
                                        clip-rule="evenodd"
                                    />
                                </svg>
                            </button>
                        </form>
                        <p className="text-slate-500">
                            We'll get in touch with you
                            <span className="font-bold"> soon</span>.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}
